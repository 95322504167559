import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import VerificationsApp from './VerificationsApp';
import Verified from './Verified';
import ProgressBar from './ProgressBar';
import FeedbackView from './FeedbackView';
import VerifyForm from './VerifyForm';

/**
 * System Custom definitions
 */
window.CONSTANTS = {};
window.CONSTANTS.ENDPOINTS = {};
window.CONSTANTS.baseName = '/';
window.CONSTANTS.c4kAPIURL = 'https://api.computers4kids.co.za/';
window.CONSTANTS.ENDPOINTS.verify = 'c4kmain/verify/';
window.CONSTANTS.verifyData = {};


/**
 * @returns The Entry Point Hook
 */
export default function Verifications() 
{
    return (
        <Router basename={window.CONSTANTS.baseName}>
            <Switch>
                <Route exact path="/">
                    <VerifyForm />
                </Route>
                <Route path="/:verificationId">
                    <VerificationsApp />
                    <Verified />
                    <FeedbackView />
                    <ProgressBar />
                </Route>
            </Switch>
        </Router>
    );
}