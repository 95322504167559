import React from 'react';
import Utils from './Utils';

/**
 * Use this to display any messages in the app
 */
export default function FeedbackView()
{
    return(
        <div onClick={() => { Utils.hideFeedbackView() }} className="theFeedbackView animate__animated" style={{ display: 'none' }} id="appFeedback">
            <div id="feedbackContent">   
            </div>
            <div id="touchToHide">Touch to hide this message</div>
        </div>
    )
}
