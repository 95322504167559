import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import Assets from './Assets';

/**
 * 
 * @returns 
 */
export default function Verified() {
    return (
        <Router>
            <div style={{display: 'none'}} className="Verified animate__animated animate__fadeIn">
                <div id="verifyData">
                    <div>
                        <img id="verifyIcon" title="Verified" src={Assets.verified} alt="Verified" />
                    </div>
                    <div id="VerifiedData">
                        <table id="VerifyDataTable">
                            <tbody>
                                <tr>
                                    <td>Certificate Type: </td>
                                    <td id="certType">$certificateType</td>
                                </tr>
                                <tr>
                                    <td>CentreName:</td>
                                    <td id="centreName">$centreName</td>
                                </tr>
                                <tr>
                                    <td>Licensee:</td>
                                    <td id="licensee">$licensee</td>
                                </tr>
                                <tr>
                                    <td>Date Issued:</td>
                                    <td id="dateIussued">$dateIssued</td>
                                </tr>
                                <tr>
                                    <td>Status:</td>
                                    <td id="status">$status</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Router>
    )
}