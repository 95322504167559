import React from 'react';
import './ProgressBarStyles.css';

export default function ProgressBar()
{
    const styles = { width: '60px', height: '60px', padding: '5px', margin: 'auto',marginTop: '5px' }
    return(
        <div id="progressBar" style={{display: 'none'}} className="animate__animated animate__fadeIn">
            <div id="smartLoader" style={styles}>
                <svg viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
                    <circle id="c" fill="none" strokeWidth="8" style={{ stroke: '#b50015'}} cx="45" cy="45" r="40" />
                </svg>
            </div>
            <div id="doingWhat">
                <p><b>Processing...</b></p>
            </div>
        </div>
    )
}