import bkgAsset from './assets/certificateborder2.png';
import verified from './assets/verified.png';
import bkgAsset2 from './assets/certificateborder2.png';
import verifyFailed from './assets/verificationfailed.png';

const ImagesAssets = {
    bkgAsset: bkgAsset,
    verified: verified,
    bkgAsset2: bkgAsset2,
    verifyFailed: verifyFailed
};

export default ImagesAssets;