/**
**Bismillahir rahmaanir raheem
**In the name of Allah, the Most Gracious, the Most Merciful
**Allah is the Creator of the scientist and his inventions
**/
import Assets from './Assets';
import axios from 'axios';
import TextStore from './TextStore';

/**
 * Dawood Morris Kaundama
 * Thu 11 Mar 2021 14:16:46 SAST
 * Computer 4 Kids
 */

const Utils = {
    api: {
        url: 'https://api.computers4kids.co.za/serve/'
    }
};


/**
 * Show the feedback view comp
 * @param {*} shakeX - boole, whethean
 */
Utils.showFeedbackView = (shakeX = false) => 
{
    const feedbackView = Utils.$PDOM('.theFeedbackView')[0];
    if (shakeX) 
    {
        feedbackView.classList.remove('animate__rotateOut');
        feedbackView.classList.remove('animate__fadeOut');
        feedbackView.classList.add('animate__shakeX');
        Utils.showElement(feedbackView);
    }
    else 
    {
        feedbackView.classList.remove('animate__rotateOut');
        feedbackView.classList.remove('animate__fadeOut');
        feedbackView.classList.add('animate__fadeIn');
        Utils.showElement(feedbackView);
    }
}

/**
 * Shows an element. It should have styling/animations already in place
 * @param {*} DOMElObject 
 */
Utils.showElement = (DOMElObject) => 
{
    DOMElObject.style.display = 'block';
}

/**
 * Hide an element immediately
 * @param {*} DOMElObject 
 */
Utils.hideElementImmediately = (DOMElObject) => 
{
    DOMElObject.style.display = 'none';
}

/**
 * Show the feedback view comp
 */
Utils.hideFeedbackView = () => 
{
    const feedbackView = Utils.$PDOM('.theFeedbackView')[0];
    feedbackView.classList.remove('animate__fadeIn');
    feedbackView.classList.remove('animate__shakeX');
    feedbackView.classList.add('animate__rotateOut');
    Utils.hideAfterOneSec(feedbackView);
}

/**
 * Hide an aelement after one sec. Assumes el is not null object
 * @param {*} el 
 */
Utils.hideAfterOneSec = (el) => 
{
    setTimeout(() => 
    {
        Utils.hideElement(el);
    }, 1000);
}

/** Grab pure DOM
 *@param {string} alementAttribute One of [#,.] to use to grap the pure DOM from the DOM object
*/
Utils.$PDOM = (elAttr) => 
{
    let attributeIdentifier = elAttr !== null ? elAttr.split("")[0] : false;
    if (attributeIdentifier) 
    {
        switch (attributeIdentifier) 
        {
            case '#':
                return document.getElementById(elAttr.substr(1));
            case '.':
                return document.getElementsByClassName(elAttr.substr(1));
            default:
                return document.getElementsByTagName(elAttr);
        }
    }
}

/**
 * Set the html content of an element
 * @param {object} targetElement The HTML DOM object
 * @param {string} htmlContent The comntent to set in the targetElement
 */
Utils.setHTMLContent = (targetElement, htmlContent) => 
{
    targetElement.innerHTML = htmlContent;
}

/**
 * Show the progress bar
 */
Utils.showProgressBar = () => 
{
    Utils.showElement(Utils.$PDOM('#progressBar'));
}

/**
 * Hide the progress bar
 */
Utils.hideProgressBar = () => 
{
    Utils.hideElement(Utils.$PDOM('#progressBar'));
}

/**
 * When not verified, redirect to verifications home page 
 **/
Utils.notVerified = () => 
{
    console.log('Not verified...');
}

/**
 * Show data when a certificate is verified
 **/
Utils.showVerifiedData = ()=>
{
    console.log('Verified...');
}

/**
 * Hide an element after one sec
 * @param {object} DOMEObject 
 */
Utils.hideElement = (DOMEObject) => 
{
    setTimeout(() => {
        DOMEObject.style.display = 'none';
    }, 1000);
}

/**
 * Attempt verification
 * @param {object} verificationId 
 */
Utils.doVerification = (verificationId) => {
    const justASec = '<p><b>Just a sec...</b></p>';
    Utils.setHTMLContent(Utils.$PDOM('#doingWhat'),justASec);
    Utils.showProgressBar();
    const data = {
        endpoint: 'CertificateVerifications',
        payload: {
            action: 'verify',
            data: {
                verificationId: verificationId.verificationId,
                requestOrigin: 'Web App'
            }
        }
    };
    axios.post(Utils.api.url, {data: data},{
        headers: {
            'Content-Type': 'application/json',
            'apiToken': 'liveInternal_mHEWkQ6OIML6VydCd7FzJEPKlVhfU56lx70wxteiHhnHGrD8AzAlTCDQVzGcIMgd'
        }
    })
    .then(response => {
        Utils.hideProgressBar();
        if(response.data.data.status) {
            Utils.verificationSuccess(response.data.data);
        } else {
            Utils.verificationFailed();
        }
    })
    .catch(error => {
        console.log('Error: ',error.message);
        Utils.hideProgressBar();
        Utils.requestFailed();
    });
}

/**
 * Show that the verification was successful
 * @param {object} data 
 */
Utils.verificationSuccess = (data) =>
{
    Utils.setHTMLContent(Utils.$PDOM('#certType'), data.certificateType);
    Utils.setHTMLContent(Utils.$PDOM('#centreName'), data.centreName);
    Utils.setHTMLContent(Utils.$PDOM('#licensee'), data.firstname+' '+data.lastname);
    Utils.setHTMLContent(Utils.$PDOM('#dateIussued'), data.dateIssued.split(' ')[0]);
    Utils.setHTMLContent(Utils.$PDOM('#status'), TextStore.statuses[data.centreStatus]);
    Utils.showElement(Utils.$PDOM('.Verified')[0]);
}

/**
 * Show that the verification failed 
 */
Utils.verificationFailed = () => 
{
    const verifyIcon = Utils.$PDOM('#verifyIcon');
    verifyIcon.src = Assets.verifyFailed;
    verifyIcon.title = 'Verification Failed';
    let html = '<h2 id="failedVerify">The authenticity of the supplied certificate could not ';
    html += 'be verified. Please <a href="mailto:certifications@computers4kids.co.za?subject=An Issue With Certificate Verification">';
    html += 'report</a> this immediately to <a href="mailto:certifications@computers4kids.co.za?subject=An Issue With Certificate Verification">certifications@computers4kids.co.za</a>.</h2>';
    Utils.setHTMLContent(Utils.$PDOM('#VerifiedData'),html);
    Utils.showElement(Utils.$PDOM('.Verified')[0]);
}
/**
 * Show that the verification failed 
 */
Utils.requestFailed = () => 
{
    const verifyIcon = Utils.$PDOM('#verifyIcon');
    verifyIcon.src = Assets.verifyFailed;
    verifyIcon.title = 'Verification Failed';
    let html = '<h2 id="failedVerify">Something went wrong. We could not process the request. Please try again later</h2>';
    Utils.setHTMLContent(Utils.$PDOM('#VerifiedData'), html);
    Utils.showElement(Utils.$PDOM('.Verified')[0]);
}

/**
 * Verify a certificate
 */
Utils.verifyCertificate = () =>
{
    const verificationId = Utils.$PDOM('#certNumInput').value;
    if(verificationId) {
        window.location = './'+verificationId;
    } else {
        const errorMsg = 'Please enter a certificate number';
        const errorOutput = Utils.$PDOM('#errorInput');
        Utils.setHTMLContent(errorOutput,errorMsg);
        Utils.showElement(errorOutput);
    }
}

/**
 * Handle the Blur on the input
 */
Utils.handleBlur = () =>
{
    Utils.hideElementImmediately(Utils.$PDOM('#errorInput'));
}

export default Utils;