import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    useParams} from 'react-router-dom';
import './Styles.css';
import './Media.css';
import 'animate.css';
import Utils from './Utils';

export default function C4KOnboardingApp() 
{
    const verificationId = useParams();
    useEffect(() => 
    {
        Utils.doVerification(verificationId);
    });

    return (
        <Router>
            <div className="VerificationsApp">
            </div>
        </Router>
    )
}