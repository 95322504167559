import React from 'react';
import TextStore from './TextStore';
import Button from '@material-ui/core/Button';
import Utils from './Utils';

/**
 * 
 * @returns 
 */
export default function VerifyForm() {
    return(
        <div className="VerifyForm animate__animated animate_fadeIn">
            <h1>
                {TextStore.header}
            </h1>
            <h3>
                {TextStore.explain1}
                <br></br>
                {TextStore.explain2}
            </h3>
            <p id="extraInfo">
                {TextStore.explain3}
            </p>
            <input id="certNumInput" type="text" onFocus={Utils.handleBlur} placeholder="Enter certificate number" />
            <p style={{display: 'none'}} id="errorInput" className="animate__animated animate__shakeX">{''}</p>
            <p></p>
            <Button onClick={Utils.verifyCertificate} id="VerifyButton" style={{ color: "#ffffff", backgroundColor: "green" }} contained="true">
                Verify Certificate
            </Button>
        </div>
    )
}